import {
  GarageIcon,
  KeyIcon,
  LightIcon,
  PowerIcon,
  TemperatureIcon,
} from "components/Icons";
import { OrderedMap, OrderedSet } from "immutable";
import PropTypes from "prop-types";
import { seconds } from "utils/dates";

export const TOP_BAR_WIDTH = 210;
export const TOP_BAR_HEIGHT = 66;
export const MENU_Z_INDEX = 10;
export const MENU_WIDTH = 210;
export const GUTTER = 8;
export const GUTTER_LARGE = 20;
export const BORDER_RADIUS = "4px";
export const CUBIC_BEZIER = "cubic-bezier(0.63, 0.64, 0.3, 1)";
export const SYSTEM_TYPES = {
  XR: "XR",
  XT: "XT",
};
export const REM_TO_PX = 16;
export const PX_TO_REM = 1 / REM_TO_PX;
export const CONNECTION_LENGTH_IN_MINUTES = 3;
export const DEVICE_TYPES = {
  BARRIER_OPERATOR: "BARRIER_OPERATOR",
  LIGHT: "LIGHT",
  LOCK: "LOCK",
  THERMOSTAT: "THERMOSTAT",
  APPLIANCE: "APPLIANCE",
};
export const DEVICE_TYPE_NAMES = {
  [DEVICE_TYPES.BARRIER_OPERATOR]: "garage door",
  [DEVICE_TYPES.LIGHT]: "light",
  [DEVICE_TYPES.LOCK]: "lock",
  [DEVICE_TYPES.THERMOSTAT]: "thermostat",
  [DEVICE_TYPES.APPLIANCE]: "appliance",
};
export const ICON_COMPONENTS_BY_DEVICE_TYPE = {
  [DEVICE_TYPES.BARRIER_OPERATOR]: GarageIcon,
  [DEVICE_TYPES.LIGHT]: LightIcon,
  [DEVICE_TYPES.LOCK]: KeyIcon,
  [DEVICE_TYPES.THERMOSTAT]: TemperatureIcon,
  [DEVICE_TYPES.APPLIANCE]: PowerIcon,
};
export const deviceTypePropType = PropTypes.oneOf(
  Object.keys(DEVICE_TYPES).map((key) => DEVICE_TYPES[key])
);
export const BREAKPOINTS = OrderedMap([
  ["xxxxxlarge", 2000],
  ["xxxxlarge", 1620],
  ["xxxlarge", 1440],
  ["xxlarge", 1220],
  ["xlarge", 1024],
  ["large", 960],
  ["medium", 768],
  ["small", 600],
  ["xsmall", 480],
  ["xxsmall", 320],
  ["base", 0],
]);
export const VIDEO_CHANNEL_CLIP_RECORD_LENGTH = seconds(20);
export const DOOR_TYPES = {
  DOOR: "734_DOOR",
  LOCK: "LOCK",
  BARRIER_OPERATOR: "BARRIER_OPERATOR",
};
export const SCHEDULE_TYPES = {
  XT_ARMING: "XT_ARMING",
  XT_FAVORITE: "XT_FAVORITE",
  XT_OUTPUT: "XT_OUTPUT",
  XR: "XR",
};
export const FOOTNOTE_SYMBOL = "†";
export const TROUBLE_SUBCATEGORY_IDS = {
  zoneOrDeviceTrouble: 23,
  zwaveDeviceLowBattery: 30,
  acPowerFailure: 31,
  acPowerRestored: 32,
  systemLowBattery: 34,
  systemTest: 36,
  zoneInactivity: 77,
  nacTrouble: 144,
  nacRestore: 145,
  auxPowerTrouble: 146,
  auxPowerRestored: 147,
  groundFaultTrouble: 150,
  groundFaultRestored: 151,
  zoneRestored: 152,
  standbyBatteryRestored: 125,
};
export const ALL_TROUBLE_IDS = OrderedSet.of(
  TROUBLE_SUBCATEGORY_IDS.zoneOrDeviceTrouble,
  TROUBLE_SUBCATEGORY_IDS.zwaveDeviceLowBattery,
  TROUBLE_SUBCATEGORY_IDS.acPowerFailure,
  TROUBLE_SUBCATEGORY_IDS.acPowerRestored,
  TROUBLE_SUBCATEGORY_IDS.systemLowBattery,
  TROUBLE_SUBCATEGORY_IDS.systemTest,
  TROUBLE_SUBCATEGORY_IDS.zoneInactivity,
  TROUBLE_SUBCATEGORY_IDS.nacTrouble,
  TROUBLE_SUBCATEGORY_IDS.nacRestore,
  TROUBLE_SUBCATEGORY_IDS.auxPowerTrouble,
  TROUBLE_SUBCATEGORY_IDS.auxPowerRestored,
  TROUBLE_SUBCATEGORY_IDS.groundFaultTrouble,
  TROUBLE_SUBCATEGORY_IDS.groundFaultRestored,
  TROUBLE_SUBCATEGORY_IDS.standbyBatteryRestored
);
export const ALARMS_SUBCATEGORY_IDS = {
  BURG: 1,
  ALARM_CANCELLED: 13,
  ALARM_VERIFIED: 14,
  AMBUSH: 15,
  EMERGENCY: 24,
  FIRE: 25,
  PANIC: 26,
  BASE: 28,
  SUPERVISORY: 79,
  CO: 83,
};
export const ALL_ALARMS_SUBCATEGORY_IDS = OrderedSet.of(
  ALARMS_SUBCATEGORY_IDS.BURG,
  ALARMS_SUBCATEGORY_IDS.ALARM_CANCELLED,
  ALARMS_SUBCATEGORY_IDS.ALARM_VERIFIED,
  ALARMS_SUBCATEGORY_IDS.AMBUSH,
  ALARMS_SUBCATEGORY_IDS.EMERGENCY,
  ALARMS_SUBCATEGORY_IDS.FIRE,
  ALARMS_SUBCATEGORY_IDS.PANIC,
  ALARMS_SUBCATEGORY_IDS.BASE,
  ALARMS_SUBCATEGORY_IDS.SUPERVISORY,
  ALARMS_SUBCATEGORY_IDS.CO
);
export const ARMED_SUBCATEGORY_IDS = {
  ARMED_ALL: 2,
  ARMED_PERIMETER: 3,
  SOME_AREAS_ARMED: 4,
  ALL_AREAS_ARMED: 6,
  ARMED_AWAY: 9,
  ARMED_HOME: 11,
  ARMED_SLEEP: 12,
  LATE_TO_CLOSE: 29,
  EARLY_TO_CLOSE: 37,
  ARMED_STAY: 39,
};
export const ALL_ARMED_SUBCATEGORY_IDS = OrderedSet.of(
  ARMED_SUBCATEGORY_IDS.ARMED_ALL,
  ARMED_SUBCATEGORY_IDS.ARMED_PERIMETER,
  ARMED_SUBCATEGORY_IDS.SOME_AREAS_ARMED,
  ARMED_SUBCATEGORY_IDS.ALL_AREAS_ARMED,
  ARMED_SUBCATEGORY_IDS.ARMED_AWAY,
  ARMED_SUBCATEGORY_IDS.ARMED_HOME,
  ARMED_SUBCATEGORY_IDS.ARMED_SLEEP,
  ARMED_SUBCATEGORY_IDS.LATE_TO_CLOSE,
  ARMED_SUBCATEGORY_IDS.EARLY_TO_CLOSE,
  ARMED_SUBCATEGORY_IDS.ARMED_STAY
);
export const DISARMED_SUBCATEGORY_IDS = {
  SOME_AREAS_DISARMED: 7,
  ALL_AREAS_DISARMED: 10,
  LATE_TO_OPEN: 38,
  START: 80,
};
export const ALL_DISARMED_SUBCATEGORY_IDS = OrderedSet.of(
  DISARMED_SUBCATEGORY_IDS.SOME_AREAS_DISARMED,
  DISARMED_SUBCATEGORY_IDS.ALL_AREAS_DISARMED,
  DISARMED_SUBCATEGORY_IDS.LATE_TO_OPEN,
  DISARMED_SUBCATEGORY_IDS.START
);
export const ARMING_DISARMING_SUBCATEGORY_IDS = {
  ARMED: ALL_ARMED_SUBCATEGORY_IDS,
  DISARMED: ALL_DISARMED_SUBCATEGORY_IDS,
  ZONE_BYPASS: 19,
};

export const ALL_ARMING_DISARMING_SUBCATEGORY_IDS = OrderedSet.of(
  Object.values(ARMED_SUBCATEGORY_IDS).join(","),
  Object.values(DISARMED_SUBCATEGORY_IDS).join(","),
  ARMING_DISARMING_SUBCATEGORY_IDS.ZONE_BYPASS
);

export const DOOR_STATUS_SUBCATEGORY_IDS = {
  LOCKED: 66,
  UNLOCKED: 67,
  DOOR_FORCED_OPEN: 68,
};

export const ALL_DOOR_STATUS_SUBCATEGORY_IDS = OrderedSet.of(
  DOOR_STATUS_SUBCATEGORY_IDS.LOCKED,
  DOOR_STATUS_SUBCATEGORY_IDS.UNLOCKED,
  DOOR_STATUS_SUBCATEGORY_IDS.DOOR_FORCED_OPEN
);

export const LOCKDOWN_STATUS_SUBCATEGORY_IDS = {
  CLEARED: 87,
  INITIATED: 88,
};

export const ALL_LOCKDOWN_STATUS_SUBCATEGORY_IDS = OrderedSet.of(
  LOCKDOWN_STATUS_SUBCATEGORY_IDS.CLEARED,
  LOCKDOWN_STATUS_SUBCATEGORY_IDS.INITIATED
);

export const ARMING_EXCEPTION_STATUS_SUBCATEGORY_IDS = {
  EARLY_TO_CLOSE: 37,
  LATE_TO_OPEN: 38,
};

export const ALL_ARMING_EXCEPTION_STATUS_SUBCATEGORY_IDS = OrderedSet.of(
  ARMING_EXCEPTION_STATUS_SUBCATEGORY_IDS.EARLY_TO_CLOSE,
  ARMING_EXCEPTION_STATUS_SUBCATEGORY_IDS.LATE_TO_OPEN
);

export const DOOR_ACCESS_STATUS_SUBCATEGORY_IDS = {
  ACCESS_GRANTED: 40,
  ARMED_AREA: 41,
  FAILED_TO_EXIT: 42,
  INVALID_AREA: 43,
  INVALID_CODE: 44,
  INVALID_LEVEL: 45,
  INVALID_TIME: 46,
  INACTIVE_USER: 85,
  DOOR_FORCED: 68,
};

export const ALL_DOOR_ACCESS_STATUS_SUBCATEGORY_IDS = OrderedSet.of(
  DOOR_ACCESS_STATUS_SUBCATEGORY_IDS.ACCESS_GRANTED,
  DOOR_ACCESS_STATUS_SUBCATEGORY_IDS.ARMED_AREA,
  DOOR_ACCESS_STATUS_SUBCATEGORY_IDS.FAILED_TO_EXIT,
  DOOR_ACCESS_STATUS_SUBCATEGORY_IDS.INVALID_AREA,
  DOOR_ACCESS_STATUS_SUBCATEGORY_IDS.INVALID_CODE,
  DOOR_ACCESS_STATUS_SUBCATEGORY_IDS.INVALID_LEVEL,
  DOOR_ACCESS_STATUS_SUBCATEGORY_IDS.INVALID_TIME,
  DOOR_ACCESS_STATUS_SUBCATEGORY_IDS.INACTIVE_USER,
  DOOR_ACCESS_STATUS_SUBCATEGORY_IDS.DOOR_FORCED
);

export const BASE_VERTICAL_PADDING_IN_REM = 0.5;
export const BASE_VERTICAL_PADDING_IN_PX = BASE_VERTICAL_PADDING_IN_REM * 16;
export const BASE_VERTICAL_PADDING_LARGE_IN_REM = 1;
export const BASE_VERTICAL_PADDING_LARGE_IN_PX =
  BASE_VERTICAL_PADDING_LARGE_IN_REM * 16;
export const BASE_LIST_ITEM_HEIGHT_IN_REM = 3;
export const BASE_LIST_ITEM_HEIGHT_IN_PX = BASE_LIST_ITEM_HEIGHT_IN_REM * 16;
