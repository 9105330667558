import { commitLocalUpdate, type Environment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import { AppNotification, AppNotificationType, BulkImportSnapshotToClear } from "securecom-graphql/client";
import uuid from "uuid/v4";

interface Props {
  type: AppNotificationType,
  id?: string,
  autoDismiss?: boolean,
  action?: string,
  entity?: string,
  message?: string,
  progress?: number,
  link?: string,
}

export type NotificationHandler = ({
  type,
  id,
  autoDismiss,
  action,
  entity,
  message,
  progress,
  link
}: Props) => void

/**
  Returns a function that takes the following params, and can be called to create a notification toast:
  @prop `env`:         Required. Relay environment provided by the useRelayEnvironment() hook.

  Properties of the returned function
  @prop `type`:        Required. Type of notification (Success, Progress, Error) from the AppNotificationType enum. (AppNotificationType.SUCCESS)
  @prop `id`:          Optional. Identifier key used in the relay store. Will use a random internal UUID if not provided. 
                                 Please create and pass in an ID if you need to reference or invalidate a notification later.
  @prop `message`:     Optional. Message string that will be displayed, displays a default message if not provided.
  @prop `entity`:      Optional. Entity string that will be displayed, typically the username or item identifier. (Saving <UserName>)
  @prop `action`:      Optional. Action string that will be displayed, the verb being enacted on the entity. (<Saving> UserName)
  @prop `autoDismiss`: Optional. Automatically dismiss the toast after a timeout. Defaults to true for SUCCESS notifications.
  @prop `progress`:    Optional. Used to show the progress amount in the radial progress icon.
  @prop `link`:        Optional. Used to show the view and dismiss buttons for bulk-user-code-import
 */
export const useRegisterAppNotification: (env: Environment) => NotificationHandler = (env) => {
  return ({
    type,
    id,
    autoDismiss,
    action,
    entity,
    message,
    progress,
    link
  }: Props) => {
    let duplicate = false;
    if (id !== undefined) {
      commitLocalUpdate(env, (store) => {
        const root = store.getRoot();
        const notifications = root.getLinkedRecords("appNotifications") ?? [];
  
        duplicate = notifications?.filter(
          (n) => n.getValue("id") === id
        ).length > 0;
      });
    }

    if (!duplicate) {
      commitLocalUpdate(env, store => {
        const root = store.getRoot();
        const newId = String(id ?? uuid())
        const notification = store.create(
          newId,
          'AppNotification'
        ) as RecordProxy<AppNotification>;

        notification.setValue(newId, "id")
        notification.setValue(type, "type");
        action && notification.setValue(action, "action");
        entity && notification.setValue(entity, "entity");
        message && notification.setValue(message, "message");
        progress && notification.setValue(progress, "progress");
        link && notification.setValue(link, "link");
        autoDismiss !== undefined && notification.setValue(autoDismiss, "autoDismiss");

        const notifications = root.getLinkedRecords('appNotifications') ?? [];
        root.setLinkedRecords([...notifications, notification], 'appNotifications');
      })
    }
  };
};

/**
  Returns a function that updates the progress of a notification.
  @prop `id`: Required. Identifier for a notification in the relay store to be updated.
  @prop `progress`: Required. 0-100 percentage indicator of progress.
 */
export const useUpdateAppNotificationProgress: (env: Environment) => ({ id, progress }: {id: string, progress: number}) => void = (env) => {
  return ({ id, progress }) => {
    commitLocalUpdate(env, (store) => {
      const notification = store.get(id) as RecordProxy<Notification>
      notification.setValue(progress, "progress");
    });
  }
}

/**
  Returns a function that checks if a notification exists.
  @prop `id`: Required. Identifier for a notification in the relay store to be checked for presence.
 */
export const useAppNotificationExists: (env: Environment) => (id: string) => boolean = (env) => {
  return (id) => {
    let notification;
    commitLocalUpdate(env, (store) => {
      notification = store.get(id) as RecordProxy<Notification>
    });
    return Boolean(notification)
  }
}

/**
  Returns a function that unceremoniously removes a current notification from the store; 
  will immediately stop displaying without animation.
  @prop `id`: Required. Identifier for a notification in the relay store to be removed.
 */
export const useClearAppNotification: (env: Environment) => (id: string) => void = (env) => {
  return (id) => {
    commitLocalUpdate(env, (store) => {
      const root = store.getRoot();
      const notifications = root.getLinkedRecords("appNotifications") ?? [];

      const updatedNotifications = notifications?.filter(
        (n) => n.getValue("id") !== id
      );
      store.delete(id);
      root.setLinkedRecords(updatedNotifications, "appNotifications");
    });
  }
}

/**
  Returns a function that unceremoniously removes ALL current notifications from the store; 
  will immediately stop displaying without animation.
 */
export const useClearAllAppNotifications: (env: Environment) => () => void = (env) => {
  return () => {
    commitLocalUpdate(env, (store) => {
      const root = store.getRoot();
      root.setLinkedRecords([], "appNotifications");
    });
  }
}

/**
  Returns a function that clears any bulk import snapshot indicators from the store.
  Used in /contexts/bulk-user-code-import-context.tsx
 */
export const useClearBulkImportSnapshots: (env: Environment) => () => void = (env) => {
  return () => {
    commitLocalUpdate(env, (store) => {
      const root = store.getRoot();
      root.setLinkedRecords([], "bulkImportSnapshotsToClear");
    });
  }
}

/**
  Returns a function that adds a bulk import snapshot identifier to the relay store as a signal for removal.
  Used in /contexts/bulk-user-code-import-context.tsx
  @prop `id`: Required. Identifier for a bulk import snapshot to be removed.
 */
export const useAddBulkImportSnapshotToClear: (env: Environment) => (id: string) => void = (env) => {
  return (id) => {
    commitLocalUpdate(env, (store) => {
      const root = store.getRoot();
      const newID = uuid();
      const snapshot = store.create(
        newID,
        'BulkImportSnapshotToClear'
      ) as RecordProxy<BulkImportSnapshotToClear>;
      id && snapshot.setValue(id, "snapshotId");
      const snapshots = root.getLinkedRecords('bulkImportSnapshotsToClear') ?? [];
      root.setLinkedRecords([...snapshots, snapshot], 'bulkImportSnapshotsToClear');
    });
  }
}
