import useRefInRender from "common/react-hooks/use-ref-in-render";
import React, { createContext, HTMLAttributes, useContext } from "react";

export type ScrollElement = HTMLDivElement | typeof window;

const ScrollElementReactContext = createContext<ScrollElement>(window);

const ScrollElementContext = (
  props: HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode | React.ReactNode[];
  }
) => {
  const rootRef = React.useRef<HTMLDivElement | null>(null);
  const scrollElement = useRefInRender(rootRef) ?? window;

  return (
    <ScrollElementReactContext.Provider value={scrollElement}>
      <div {...props} ref={rootRef} />
    </ScrollElementReactContext.Provider>
  );
};

export default ScrollElementContext;

export const useCurrentScrollElement = () =>
  useContext(ScrollElementReactContext);
