import { FUSEJS_SCORE_DISTANCE, FUSEJS_SCORE_THRESHOLD } from "config/app";
import Fuse, { FuseOptionKey } from "fuse.js";

/**
 * @description Search an array of objects given an array of keys.
 * @returns If a query string is present, returns search results. Otherwise returns the initial data.
 */
const useSearch = <T>(
  data: readonly T[],
  searchKeys: (keyof T)[] | string[],
  query?: string,
  shouldSort?: boolean
) => {
  //Options documentation: https://www.fusejs.io/api/options.html
  const fuse = new Fuse(data, {
    keys: searchKeys as FuseOptionKey<T>[],
    shouldSort: shouldSort,
    threshold: FUSEJS_SCORE_THRESHOLD,
    distance: FUSEJS_SCORE_DISTANCE,
  });

  const results = query ? fuse.search(query).map(({ item }) => item) : data;

  return results as T[];
};

export default useSearch;

/**
 *  * @description Search an array of objects given an array of keys. For use with legacy components that can't use hooks or operations performed inside `useCallback`.
 * @returns If a query string is present, returns search results. Otherwise returns the initial data.
 * @deprecated Prefer using `useSearch` if possible.
 */
export const search = <T>(
  data: readonly T[],
  searchKeys: (keyof T)[] | string[],
  query?: string,
  shouldSort?: boolean
) => {
  //Options documentation: https://www.fusejs.io/api/options.html
  const fuse = new Fuse(data, {
    keys: searchKeys as FuseOptionKey<T>[],
    shouldSort: shouldSort,
    threshold: FUSEJS_SCORE_THRESHOLD,
    distance: FUSEJS_SCORE_DISTANCE,
  });

  const results = query ? fuse.search(query).map(({ item }) => item) : data;

  return results as T[];
};
