import { vkApiInstance } from "apis/vk";
import Portal from "common/components/web/Portal";
import AppNotifications from "components/AppNotifications";
import Breakpoints from "components/Breakpoints";
import FadeInOut from "components/FadeInOut";
import FullHeightMinWrapper from "components/FullHeightMinWrapper";
import { BREAKPOINTS } from "constants/index";
import ConfirmModal from "containers/ConfirmModal";
import PendingConfirmModal from "containers/PendingConfirmModal";
import useEffectOnce from "hooks/useEffectOnce";
import { useDispatch, useStoreSelector } from "hooks/useRedux";
import { useDaemons } from "hooks/useSaga";
import { CHANGE_PASSWORD_ROUTE, CREATE_PASSWORD_ROUTE } from "paths";
import React from "react";
import Helmet from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { RelayEnvironmentProvider } from "relay-environment";
import store from "store";
import { logIn } from "store/auth/actions";
import { UNAUTHORIZED_RESPONSE_RECEIVED } from "store/auth/constants";
import {
  logInDaemon,
  receiveAuthenticatedUserDataDaemon,
} from "store/auth/sagas";
import { selectAuthToken, selectRefreshToken } from "store/auth/selectors";
import { historyDaemon } from "store/history/sagas";
import { receiveOnboardUserDataDaemon } from "store/systems/sagas";

const LogInView = React.lazy(() => import("containers/LogInView"));
const LoggedInView = React.lazy(() => import("containers/LoggedInView"));
const InvitePage = React.lazy(() => import("pages/InvitePage"));
const CampaignConfirm = React.lazy(() => import("pages/CampaignConfirm"));
const ServiceAppointmentCancellation = React.lazy(
  () => import("pages/ServiceAppointmentCancellation")
);
const ChangePasswordPage = React.lazy(() => import("pages/ChangePasswordPage"));

function AppContainer() {
  const authToken = useStoreSelector(selectAuthToken);
  let refreshToken = useStoreSelector(selectRefreshToken);
  const dispatch = useDispatch();

  useDaemons(
    logInDaemon, //Authenticate
    receiveAuthenticatedUserDataDaemon, //call onboardUser
    historyDaemon, //Instantiate Redux history
    receiveOnboardUserDataDaemon //Check armed statuses (panel online)
  );

  useEffectOnce(async () => {
    if (authToken) {
      try {
        const response = await vkApiInstance.get("/v3/2fa_settings");
        if (!refreshToken) {
          dispatch({ type: UNAUTHORIZED_RESPONSE_RECEIVED });
        } else {
          const { data } = response;
          const state = store.getState();
          //@ts-ignore //TODO: Remove this after selectors are replaced
          refreshToken = selectRefreshToken(state);

          if (
            (data.two_factor_dealer_required ||
              data.two_factor_customer_required) &&
            !data.two_factor_required
          ) {
            dispatch({ type: UNAUTHORIZED_RESPONSE_RECEIVED });
          } else {
            //@ts-ignore //TODO: Remove this after selectors are replaced
            dispatch(logIn({ authToken, refreshToken, now: new Date() }));
          }
        }
      } catch {
        const state = store.getState();
        //@ts-ignore //TODO: Remove this after selectors are replaced
        refreshToken = selectRefreshToken(state);

        if (refreshToken) {
          //@ts-ignore //TODO: Remove this after selectors are replaced
          dispatch(logIn({ authToken, refreshToken, now: new Date() }));
        } else {
          dispatch({ type: UNAUTHORIZED_RESPONSE_RECEIVED });
        }
      }
    }
  });

  return (
    <Breakpoints.Provider breakpoints={BREAKPOINTS}>
      <FullHeightMinWrapper>
        <Helmet titleTemplate="%s | Virtual Keypad" />
        <RelayEnvironmentProvider>
          <Switch>
            <Route
              exact
              path={CHANGE_PASSWORD_ROUTE}
              render={(props) => (
                <FadeInOut>
                  <ChangePasswordPage {...props} />
                </FadeInOut>
              )}
            />
            <Route
              exact
              path={CREATE_PASSWORD_ROUTE}
              render={(props) => (
                <FadeInOut>
                  <ChangePasswordPage {...props} />
                </FadeInOut>
              )}
            />
            <Route exact path="/invite" component={InvitePage} />
            <Route exact path="/campaign-confirm" component={CampaignConfirm} />
            <Route
              exact
              path="/cancel-service-appointment"
              component={ServiceAppointmentCancellation}
            />
            <Route
              render={(props) =>
                authToken ? (
                  <LoggedInView authToken={authToken} {...props} />
                ) : (
                  <FadeInOut>
                    {/* TODO: Get rid of these type castings after we get rid of selectors */}
                    <LogInView {...props} />
                  </FadeInOut>
                )
              }
            />
          </Switch>
          <Portal>
            <AppNotifications />
          </Portal>
        </RelayEnvironmentProvider>
        <PendingConfirmModal />
        <ConfirmModal />
      </FullHeightMinWrapper>
    </Breakpoints.Provider>
  );
}

function Root() {
  return <AppContainer />;
}

export default Root;
