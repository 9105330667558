import Maybe from "data.maybe";
import { Map } from "immutable";
import {
  call,
  compose,
  converge,
  curry,
  curryN,
  path,
  prop,
  splitEvery,
} from "ramda";
import React, { Context, useContext, useMemo, useState } from "react";
import {
  DefaultTheme,
  ThemeContext,
  ThemeProvider,
} from "styled-components/macro";
import { parseLocationSearch } from "utils";
import rgba from "utils/rgba";

export interface ExtendedDefaultTheme extends DefaultTheme {
  // utility colors
  color?: string | null;
  textColor: string;
  backgroundColor?: string | null;
  primaryBackgroundColor: string;
  secondaryBackgroundColor: string;
  tertiaryBackgroundColor: string;
  borderColor: string;
  panelBorderColor: string;
  // constant colors
  trueWhite: string;
  trueBlack: string;
  // theme specific colors
  primary: string;
  secondary: string;
  secondaryDark: string;
  secondaryLight: string;
  primary100: string;
  primary200: string;
  primary300: string;
  primary400: string;
  primary500: string;
  primary600: string;
  primary700: string;
  primary800: string;
  primary900: string;
  primary1000: string;
  // semantic colors
  success: string;
  save: string;
  caution: string;
  warning: string;
  failure: string;
  danger: string;
  // regular colors
  grayXlight: string;
  grayLight: string;
  grayMediumLight: string;
  grayMedium: string;
  gray: string;
  grayDark: string;
  grayAccent: string;
  grayAccentLight: string;
  red: string;
  orange: string;
  yellow: string;
  yellowLight: string;
  yellow100: string;
  yellow200: string;
  yellow300: string;
  yellow400: string;
  yellow500: string;
  yellow600: string;
  yellow700: string;
  yellow800: string;
  yellow900: string;
  yellow1000: string;
  green: string;
  green100: string;
  green200: string;
  green300: string;
  green400: string;
  green500: string;
  green600: string;
  green700: string;
  green800: string;
  green900: string;
  green1000: string;
  blueDark: string;
  coolBlue: string;
  purple: string;
  // theme utilities
  boxShadow: () => string;
  panelBorder: string;
  highlightColor: string;
  fallbackFontFamily: string;
  primaryFontFamily: string;
  elevation0: string;
  elevation100: string;
  elevation200: string;
  elevation300: string;
  elevation400: string;
  elevation500: string;
  statusBlue: string;
  statusGreen: string;
  statusOrange: string;
  statusRed: string;
  badgeGreen: string;
  badgeYellow: string;
  detectionRegionBlue: string;
  detectionRegionRed: string;
  detectionRegionGreen: string;
  detectionRegionYellow: string;
  bayAlarmRed: string;
  notificationBlueBackground: string;
  notificationBlueBorder: string;
  notificationBlueText: string;
}

export const defaultPrimaryTheme: Omit<
  ExtendedDefaultTheme,
  "themes" | "setTheme"
> = {
  color: null,
  textColor: "#7F8386",
  backgroundColor: null,
  primaryBackgroundColor: "#EDF0F5",
  secondaryBackgroundColor: "#FFFFFF",
  tertiaryBackgroundColor: "#F9FAFF",
  borderColor: "#F0F0F0",
  panelBorderColor: "#E0E0E0",
  trueWhite: "#FFFFFF",
  trueBlack: "#000000",
  primary: "#8F95F2",
  primary100: "#f5f5ff",
  primary200: "#ccceff",
  primary300: "#a1a4fc",
  primary400: "#8888fc",
  primary500: "#726bfa",
  primary600: "#5f57fa",
  primary700: "#4b3bf7",
  primary800: "#3424e5",
  primary900: "#1d0ebe",
  primary1000: "#0b008a",
  secondary: "#22355A",
  secondaryDark: "#0A1C3B",
  secondaryLight: "#495C82",
  success: "#5CB85C",
  save: "#5CB85C",
  caution: "#FFDA3E",
  warning: "#FF7833",
  failure: "#DD1C24",
  danger: "#ED1C24",
  grayXlight: "#F9FAFF",
  grayLight: "#EDF0F5",
  grayMediumLight: "#d0d3d8",
  grayMedium: "#6b7280",
  gray: "#383F45",
  grayDark: "#676A6C",
  grayAccent: "#CCCCCC",
  grayAccentLight: "#B3B3B3",
  red: "#ED1C24",
  red100: "#FFE3E3",
  red200: "#FFBDBD",
  red300: "#FF9B9B",
  red400: "#F86A6A",
  red500: "#EF4E4E",
  red600: "#E12D39",
  red700: "#CF1124",
  red800: "#AB091E",
  red900: "#8A041A",
  red1000: "#610316",
  orange: "#FF7833",
  yellow: "#FFDA3E",
  yellowLight: "#FFE471",
  yellow100: "#FFFBEA",
  yellow200: "#FFF3CA",
  yellow300: "#FCE588",
  yellow400: "#FADB5F",
  yellow500: "#F7C948",
  yellow600: "#F0B429",
  yellow700: "#DE911D",
  yellow800: "#CB6E17",
  yellow900: "#B44D12",
  yellow1000: "#8D2B0B",
  green: "#5CB85C",
  green100: "#E3F9E5",
  green200: "#C1EAC5",
  green300: "#A3D9A5",
  green400: "#7BC47F",
  green500: "#57AE5B",
  green600: "#3F9142",
  green700: "#2F8132",
  green800: "#207227",
  green900: "#0E5814",
  green1000: "#05400A",
  blueDark: "#2F3640",
  coolBlue: "#65A4EF",
  purple: "#8F95F2",
  boxShadow: (color = "rgba(0,0,0,0.25)") => `0 1px 8px ${color}`,
  panelBorder: `1px solid #E0E0E0`,
  highlightColor: "#FFFBCC",
  fallbackFontFamily: '"Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  primaryFontFamily:
    "Inter var,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  elevation0: "0 0 0 rgba(0,0,0,0)",
  elevation100: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  elevation200: "0 3px 6px rgba(0,0,0,0.15), 0 2px 4px rgba(0,0,0,0.12)",
  elevation300: "0 10px 20px rgba(0,0,0,0.15), 0 3px 6px rgba(0,0,0,0.10)",
  elevation400: "0 15px 25px rgba(0,0,0,0.15), 0 5px 10px rgba(0,0,0,0.05)",
  elevation500: "0 20px 40px rgba(0,0,0,0.2)",
  statusBlue: "#38b6e0",
  statusGreen: "#7cc75a",
  statusOrange: "#ffb224",
  statusRed: "#ff3324",
  badgeGreen: "#5CB85C",
  badgeYellow: "#dec316",
  detectionRegionBlue: "#285FF6",
  detectionRegionRed: "#D13A20",
  detectionRegionGreen: "#5CB85C",
  detectionRegionYellow: "#FFDA3E",
  bayAlarmRed: "#DA262E",
  notificationBlueBackground: "#CBE2EB",
  notificationBlueBorder: "#218ebe",
  notificationBlueText: "#105470",
};

const themePath = (propName: keyof ExtendedDefaultTheme) =>
  path<string>(["theme", propName]);

export const useTheme = () =>
  useContext<ExtendedDefaultTheme>(
    ThemeContext as unknown as Context<ExtendedDefaultTheme>
  );

export const useTextColor = () => useTheme().textColor;
export const themeTextColor = themePath("textColor");
export const usePrimaryBackgroundColor = () =>
  useTheme().primaryBackgroundColor;
export const themePrimaryBackgroundColor = themePath("primaryBackgroundColor");
export const useSecondaryBackgroundColor = () =>
  useTheme().secondaryBackgroundColor;
export const themeSecondaryBackgroundColor = themePath(
  "secondaryBackgroundColor"
);
export const useTertiaryBackgroundColor = () =>
  useTheme().tertiaryBackgroundColor;
export const themeTertiaryBackgroundColor = themePath(
  "tertiaryBackgroundColor"
);
export const useBorderColor = () => useTheme().borderColor;
export const themeBorderColor = themePath("borderColor");
export const useTrueWhite = () => useTheme().trueWhite;
export const themeTrueWhite = themePath("trueWhite");
export const useTrueBlack = () => useTheme().trueBlack;
export const themeTrueBlack = themePath("trueBlack");
export const usePrimary = (value = "") => {
  const theme = useTheme();
  return theme[`primary${value}`] ?? theme.primary;
};
export const themePrimary = themePath("primary");
export const themePrimaryValue = curry(
  (value, { theme }) => theme[`primary${value}`] ?? theme.primary
);
export const useSecondary = () => useTheme().secondary;
export const themeSecondary = themePath("secondary");
export const useSecondaryDark = () => useTheme().secondaryDark;
export const themeSecondaryDark = themePath("secondaryDark");
export const useSecondaryLight = () => useTheme().secondaryLight;
export const themeSecondaryLight = themePath("secondaryLight");
export const useGrayXlight = () => useTheme().grayXlight;
export const themeGrayXlight = themePath("grayXlight");
export const useGrayLight = () => useTheme().grayLight;
export const themeGrayLight = themePath("grayLight");
export const useGrayMedium = () => useTheme().grayMedium;
export const themeGrayMediumLight = themePath("grayMediumLight");
export const useGrayMediumLight = () => useTheme().grayMediumLight;
export const themeGrayMedium = themePath("grayMedium");
export const useGray = () => useTheme().gray;
export const themeGray = themePath("gray");
export const useGrayDark = () => useTheme().grayDark;
export const themeGrayDark = themePath("grayDark");
export const useGrayAccent = () => useTheme().grayAccent;
export const themeGrayAccent = themePath("grayAccent");
export const useGrayAccentLight = () => useTheme().grayAccentLight;
export const themeGrayAccentLight = themePath("grayAccentLight");
export const useRed = (value = "") => {
  const theme = useTheme();
  return theme[`red${value}`] ?? theme.red;
};
export const themeRed = themePath("red");
export const themeRedValue = curry(
  (value, { theme }) => theme[`red${value}`] ?? theme.red
);
export const useOrange = () => useTheme().orange;
export const themeOrange = themePath("orange");
export const useYellow = (value = "") => {
  const theme = useTheme();
  return theme[`yellow${value}`] ?? theme.yellow;
};
export const themeYellow = themePath("yellow");
export const useYellowLight = () => useTheme().yellowLight;
export const themeYellowLight = themePath("yellowLight");
export const useGreen = (value = "") => {
  const theme = useTheme();
  return theme[`green${value}`] ?? theme.green;
};
export const themeGreen = themePath("green");
export const themeGreenValue = curry(
  (value, { theme }) => theme[`green${value}`] ?? theme.green
);
export const useBlueDark = () => useTheme().blueDark;
export const themeBlueDark = themePath("blueDark");
export const useCoolBlue = () => useTheme().coolBlue;
export const themeCoolBlue = themePath("coolBlue");
export const usePurple = () => useTheme().purple;
export const themePurple = themePath("purple");
export const useBoxShadow = () => useTheme().boxShadow;
export const themeBoxShadow = (colorGetter = themePath("grayAccent")) =>
  converge(call, [themePath("boxShadow") as any, colorGetter]);
export const themeRgba: (
  alpha: number,
  colorGetter: (obj: any) => string | undefined
) => (obj: any) => string | undefined = curry((alpha, colorGetter) =>
  compose(rgba(alpha), colorGetter)
);
export const defaultThemeBoxShadow = themeBoxShadow(
  themeRgba(0.25, themeTrueBlack)
);
export const usePanelBorderColor = () => useTheme().panelBorderColor;
export const themePanelBorderColor = themePath("panelBorderColor");
export const usePanelBorder = () => useTheme().panelBorder;
export const themePanelBorder = themePath("panelBorder");
export const useHighlightColor = () => useTheme().highlightColor;
export const themeHighlightColor = themePath("highlightColor");
export const useFallbackFontFamily = () => useTheme().fallbackFontFamily;
export const themeFallbackFontFamily = themePath("fallbackFontFamily");
export const usePrimaryFontFamily = () => useTheme().primaryFontFamily;
export const themePrimaryFontFamily = themePath("primaryFontFamily");
export const useColor = () => useTheme().textColor;
export const themeColor = themePath("textColor");
export const useBackgroundColor = () => useTheme().backgroundColor;
export const themeBackgroundColor = themePath("backgroundColor");
export const useSuccess = () => useTheme().success;
export const themeSuccess = themePath("success");
export const useSave = () => useTheme().save;
export const themeSave = themePath("save");
export const useCaution = () => useTheme().caution;
export const themeCaution = themePath("caution");
export const useWarning = () => useTheme().warning;
export const themeWarning = themePath("warning");
export const useFailure = () => useTheme().failure;
export const themeFailure = themePath("failure");
export const useDanger = () => useTheme().danger;
export const themeDanger = themePath("danger");
export const useStatusBlue = () => useTheme().statusBlue;
export const themeStatusBlue = themePath("statusBlue");
export const useStatusGreen = () => useTheme().statusGreen;
export const themeStatusGreen = themePath("statusGreen");
export const useStatusOrange = () => useTheme().statusOrange;
export const themeStatusOrange = themePath("statusOrange");
export const useStatusRed = () => useTheme().statusRed;
export const themeStatusRed = themePath("statusRed");
export const useNotificationBlueBackground = () =>
  useTheme().notificationBlueBackground;
export const themeNotificationBlueBackground = themePath(
  "notificationBlueBackground"
);
export const useNotificationBlueBorder = () =>
  useTheme().notificationBlueBorder;
export const themeNotificationBlueBorder = themePath("notificationBlueBorder");
export const useNotificationBlueText = () => useTheme().notificationBlueText;
export const themeNotificationBlueText = themePath("notificationBlueText");

export const elevation = curry(
  (value, { theme }) => theme[`elevation${value}`] ?? theme.elevation100
);
export const hexToRGB = curryN(
  2,
  (colorFunc, ...args) =>
    `rgb(${splitEvery(2, colorFunc(...args).replace(/#/gim, ""))
      .map((twoDigitColorString) => parseInt(String(twoDigitColorString), 16))
      .join(",")})`
);
export const hexToRGBA = curryN(
  3,
  (colorFunc, alpha, ...args) =>
    `rgba(${splitEvery(2, colorFunc(...args).replace(/#/gim, ""))
      .map((twoDigitColorString) => parseInt(String(twoDigitColorString), 16))
      .join(",")}, ${alpha})`
);

const defaultThemes = Map({
  primary: defaultPrimaryTheme,
});
const bayThemes = Map({
  primary: {
    ...defaultPrimaryTheme,
    primary: defaultPrimaryTheme.bayAlarmRed,
  },
});

export const createThemes = (location) =>
  Maybe.fromNullable(location)
    //@ts-ignore
    .chain(compose(Maybe.fromNullable, prop("theme"), parseLocationSearch))
    .map((theme) => {
      switch (theme) {
        case "bay":
          return bayThemes;

        default:
          return defaultThemes;
      }
    })
    .getOrElse(defaultThemes);

const Theme = ({ children, defaultTheme, themes }) => {
  const [currentTheme, setTheme] = useState(themes.get(defaultTheme));
  return (
    <ThemeProvider
      theme={useMemo(
        () => ({
          ...currentTheme,
          setTheme,
          themes: themes.keySeq().toSet(),
        }),
        [currentTheme, themes]
      )}
    >
      {children}
    </ThemeProvider>
  );
};

export default Theme;
