/**
 *
 * LoggedInViewContext
 * @author Chad Watson
 *
 */

import { useStoreSelector } from "hooks/useRedux";
import { createDealer } from "models/dealer";
import { createAppUser } from "models/user";
import { siteIdFromPathname, systemIdFromPathname } from "paths";
import { identity } from "ramda";
import { createContext, useContext } from "react";
import { asID, fromGlobalId, toGlobalId } from "securecom-graphql/client";
import { selectAuthTokenForCustomer } from "store/auth/selectors";
import {
  selectActiveUser,
  selectAuthTokenForSystem,
  selectLocationBeforeTransitions,
} from "store/common/selectors";
import {
  selectCurrentSystemSession,
  selectSiteBillingControlSystem,
} from "store/systems/selectors";
import { immutableGet } from "utils";

export const toHashedEmail = (email: string) =>
  toGlobalId(email, "VKHashedEmail");

export const fromHashedEmail = (hashedEmail: string) => ({
  email: fromGlobalId(asID(hashedEmail))[0],
  type: fromGlobalId(asID(hashedEmail))[1],
});

export const AuthTokenContext = createContext("");
export const UserContext = createContext(createAppUser());
export const DealerContext = createContext(createDealer());

export const useActiveUser = () => useStoreSelector(selectActiveUser);

export const useAuthTokenContext = () => useContext(AuthTokenContext);
export const useUserContext = () => useActiveUser(); //UserContext doesn't properly rehydrate with sso users, so we need to use the active user for this data

export const useDealerContext = () => useContext(DealerContext);

export const useUser = (selector = identity) => selector(useUserContext());

export const useUserId = () => useUser(immutableGet("id"));

export const useCustomerId = () => useUser(immutableGet("accessibleId"));

export const usePersonId = (selector = identity) =>
  selector(useUser(immutableGet("personId")));
export const useDealer = (selector = identity) => selector(useDealerContext());

export const useDealerId = () => useDealer(immutableGet("id"));

/**
 * The optional `fn` allows you to create other values based on the auth token. Ex:
 * // loadUsers :: AuthToken -> SystemId -> void
 * <UsersPage loadUsers={useAuthToken(loadUsers)} />
 */
export const useAuthToken = (fn = identity) => fn(useAuthTokenContext());

export const useCurrentSystemSession = () =>
  useStoreSelector(selectCurrentSystemSession);

export const useActiveSystemId = () =>
  systemIdFromPathname(
    useStoreSelector(selectLocationBeforeTransitions)?.pathname ?? ""
  ).getOrElse(null);

export const useActiveSiteId = () =>
  siteIdFromPathname(
    useStoreSelector(selectLocationBeforeTransitions)?.pathname ?? ""
  );

export const useAuthTokenForSystem = (systemId) =>
  useStoreSelector((state) => selectAuthTokenForSystem(state, { systemId }));

export const useAuthTokenForSite = (siteId) => {
  const billingControlSystem = useStoreSelector((state) =>
    //@ts-ignore
    selectSiteBillingControlSystem(state, { siteId })
  );

  return useStoreSelector((state) =>
    selectAuthTokenForSystem(state, { systemId: billingControlSystem?.id })
  );
};

export const useAuthTokenForActiveSystem = () => {
  const activeSystemToken = useAuthTokenForSystem(useActiveSystemId());
  const activeSiteToken = useAuthTokenForSite(useActiveSiteId());

  return activeSystemToken ?? activeSiteToken;
};

export const useAuthTokenForActiveSite = () =>
  useAuthTokenForSite(useActiveSiteId());

export const useAuthTokenForCustomer = (customerId) =>
  useStoreSelector((state) =>
    //@ts-ignore
    selectAuthTokenForCustomer(state, { customerId: Number(customerId) })
  );

export const useActiveCustomerId = () =>
  useStoreSelector(selectActiveUser)?.accessibleId ?? undefined;

export const useAuthTokenForActiveCustomer = () =>
  useAuthTokenForCustomer(useActiveCustomerId());
